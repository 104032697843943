import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/dates/styles.css';
import {MantineProvider} from "@mantine/core";
import {Notifications} from "@mantine/notifications";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <MantineProvider defaultColorScheme="dark" theme={{
        primaryColor: 'bright-gold',
        colors: {
            'bright-gold': ['#e1e1e1', '#cbcbcb', '#d0d0f3', '#ffffff', '#e1e1e1', '#cbcbcb', '#d0d0f3', '#ffffff', '#e1e1e1', '#cbcbcb'],
        },
    }}>
        <Notifications position="top-center"/>
        <App/>
    </MantineProvider>
);