import {useEffect, useState} from "react";
import {LoadingOverlay, Table} from "@mantine/core";
import {getLinkPrefix} from "../ApiService";

const AttacksLogs = () => {
    const [isLoading, setLoading] = useState(false);
    const [logs, setLogs] = useState([]);
    useEffect(() => {
        setLoading(true)
        fetch(getLinkPrefix()+"/getAttacksLogs", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({unique: localStorage.getItem("unique")})
        }).then(response => response.json()).then(data => {
            setLogs(data.logs)
            setLoading(false)
        });
    }, []);
    return (
        <>
            <LoadingOverlay visible={isLoading} />
            <Table>
                <Table.Thead>
                    <Table.Tr>
                        <Table.Th>username</Table.Th>
                        <Table.Th>host</Table.Th>
                        <Table.Th>port</Table.Th>
                        <Table.Th>time</Table.Th>
                        <Table.Th>method</Table.Th>
                    </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                    {logs.map((log, index) => {
                        return (
                            <Table.Tr key={index}>
                                <Table.Td>{log.username}</Table.Td>
                                <Table.Td>{log.host}</Table.Td>
                                <Table.Td>{log.port}</Table.Td>
                                <Table.Td>{log.time}</Table.Td>
                                <Table.Td>{log.method}</Table.Td>
                            </Table.Tr>
                        )
                    })}
                </Table.Tbody>
            </Table>
        </>
    )
}

export default AttacksLogs;