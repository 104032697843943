import './App.css';
import {useEffect, useRef, useState} from "react";
import {
    addToLog,
    generateNewKey,
    getLinkPrefix,
    getUser,
    getUsers,
    increaseLookups,
    searchUser,
    validateKey
} from "./ApiService";
import {
    Button,
    Card,
    Center,
    Checkbox,
    Flex,
    Group,
    Input,
    LoadingOverlay,
    Modal, Select,
    Table,
    TextInput,
    Title
} from "@mantine/core";
import {useDisclosure} from "@mantine/hooks";
import {notifications} from "@mantine/notifications";
import {DatePickerInput} from "@mantine/dates";
import Login from "./pages/login";
import Stress from "./pages/stress";
import AttacksLogs from "./pages/attacks";
import LoginLogs from "./pages/logins";

function App() {
    const [user, setUser] = useState(null);
    const [data, setData] = useState(null);
    const [opened, {open, close}] = useDisclosure(false);
    const [dataOpened, dataFunctions] = useDisclosure(false);
    const [generateOpened, generateFunctions] = useDisclosure(false);
    const [adminPanelOpened, adminPanel] = useDisclosure(false);
    const [date, setDate] = useState(new Date());
    const [admin, setAdmin] = useState(false);
    const [generatedKey, setGeneratedKey] = useState(null);
    const [generating, setGenerating] = useState(false);
    const [searching, setSearching] = useState(false);
    const [loading, setLoading] = useState(true);
    const [grantAccessOpened, grantAccess] = useDisclosure(false);
    const [users, setUsers] = useState([]);
    const [usersCopy, setUsersCopy] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [attacksLogsOpened, attacksLogs] = useDisclosure(false);
    const [loginLogsOpened, loginLogs] = useDisclosure(false);
    const [apiManagementOpened, apiManagement] = useDisclosure(false);
    const [apiList, setApiList] = useState([]);
    const [API, setAPI] = useState('');
    const websiteMethod = useRef(null);
    const apiMethod = useRef(null);
    const plan = useRef(null);
    const group = useRef(null);
    const [methodsOpened, methodsFunctions] = useDisclosure(false);
    const [addMethodOpened, addMethodFunctions] = useDisclosure(false);
    const [methods, setMethods] = useState([]);
    const [editingApi, setEditingApi] = useState(0);

    const handleRemoveMethod = (id) => {
        setLoading(true);
        fetch(getLinkPrefix()+'/removeMethod', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({unique: localStorage.getItem('unique'), id: id, api_id: editingApi})
        }).then(response => response.json())
            .then(data => {
                if(data.valid) {
                    setMethods(data.methods);
                }
                setLoading(false);
            })
    }

    const handleRemoveApi = (id) => {
        setLoading(true);
        fetch(getLinkPrefix()+'/removeApi', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({unique: localStorage.getItem('unique'), id: id})
        }).then(response => response.json())
            .then(data => {
                fetch(getLinkPrefix()+'/getApiList', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({unique: localStorage.getItem('unique')})
                }).then(response => response.json())
                    .then(data => {
                        if(data.valid) {
                            setApiList(data.list);
                        }
                        setLoading(false);
                    });
            })
    }

    const handleEditMethods = (apiId) => {
        setLoading(true);
        setEditingApi(apiId);
        fetch(getLinkPrefix()+'/getMethods', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({unique: localStorage.getItem('unique'), id: apiId})
        }).then(response => response.json())
            .then(data => {
                if(data.valid) {
                    setMethods(data.methods);
                }
                setLoading(false);
                apiManagement.close();
                methodsFunctions.open();
            });
    }

    const handleAddMethod = () => {
        addMethodFunctions.open();
    }

    const handleSubmitAddMethod = () => {
        const data = {
            websiteMethodValue: websiteMethod.current.value,
            apiMethodValue: apiMethod.current.value,
            planValue: plan.current.value,
            groupValue: group.current.value
        }
        setLoading(true);
        fetch(getLinkPrefix()+'/addMethod', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({unique: localStorage.getItem('unique'), api_id: editingApi, data: data})
        }).then(response => response.json())
            .then(data => {
                if(data.valid) {
                    setMethods(data.methods);
                }
                setLoading(false);
                addMethodFunctions.close();
            });
    }
    const handleOpenGrantAccess = async () => {
        adminPanel.close();
        setLoading(true)
        const users = await getUsers(localStorage.getItem("unique"));
        if(users.valid) {
            setUsers(users.users);
            setUsersCopy(users.users);
        }
        grantAccess.open();
        setLoading(false)
    }

    const handleAdminPanel = async () => {
        adminPanel.open();
    }

    useEffect(() => {
        const searchParams = new URLSearchParams(document.location.search);
        const code = searchParams.get('code');
        if (code) {
            fetch(getLinkPrefix()+'/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({code: code})
            }).then(response => response.json()).then(data => {
                if(data.valid) {
                    localStorage.setItem('token', data.token);
                    localStorage.setItem('unique', data.unique);
                    setUser(data.user);
                    notifications.show({
                        title: 'Logged in',
                        withCloseButton: false,
                        color: 'green',
                        autoClose: 1500
                    })
                    setLoading(false);
                } else {
                    if(localStorage.getItem('token')) {
                        fetch(getLinkPrefix() + '/login', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({token: localStorage.getItem('token')})
                        }).then(response => response.json()).then(data => {
                            if (data.valid) {
                                setUser(data.user);
                                localStorage.setItem('unique', data.unique);
                                setLoading(false);
                            }
                        })
                    }
                }
            })
        } else {
            setLoading(false);
        }
    }, []);

    function handleGrantAccess() {
        setLoading(true)
        fetch(getLinkPrefix()+'/grantAccess', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({unique: localStorage.getItem("unique"), users: selectedRows, expires: date})
        }).then(response => response.json()).then(data => {
            if(data.valid) {
                notifications.show({
                    title: 'Granted access',
                    withCloseButton: false,
                    color: 'green',
                    autoClose: 1500
                })
            } else {
                notifications.show({
                    title: 'Error',
                    color: 'red',
                    autoClose: 5000
                })
            }
            fetch(getLinkPrefix()+'/getUsers', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({key: localStorage.getItem("unique")})
            }).then(response => response.json()).then(data => {
                if(data.valid) {
                    setUsers(data.users);
                    setUsersCopy(data.users);
                    setLoading(false)
                }
            })
        })
    }

    function handleRemoveAccess() {
        setLoading(true)
        fetch(getLinkPrefix()+'/removeAccess', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({unique: localStorage.getItem("unique"), users: selectedRows})
        }).then(response => response.json()).then(data => {
            if(data.valid) {
                notifications.show({
                    title: 'Removed access',
                    withCloseButton: false,
                    color: 'green',
                    autoClose: 1500
                })
            } else {
                notifications.show({
                    title: 'Error',
                    color: 'red',
                    autoClose: 5000
                })
            }
            fetch(getLinkPrefix()+'/getUsers', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({key: localStorage.getItem("unique")})
            }).then(response => response.json()).then(data => {
                if(data.valid) {
                    setUsers(data.users);
                    setUsersCopy(data.users);
                    setLoading(false)
                }
            })
        })
    }

    function handleOpenLogs(type) {
        if(type === 'login') {
            loginLogs.open();
            adminPanel.close();
        }
        if(type === 'stress') {
            attacksLogs.open();
            adminPanel.close();
        }
    }

    function handleOpenApi() {
        setLoading(true);
        fetch(getLinkPrefix()+'/getApiList', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({unique: localStorage.getItem('unique')})
        }).then(response => response.json())
            .then(data => {
                if(data.valid) {
                    setApiList(data.list);
                }
                adminPanel.close();
                apiManagement.open();
                setLoading(false);
            });
    }

    return (
        <div className="App">
            <Modal size={"xl"} opened={methodsOpened} onClose={() => {
                methodsFunctions.close();
                apiManagement.open();
            }} title="Methods">
                <Table>
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th>#</Table.Th>
                            <Table.Th>website method</Table.Th>
                            <Table.Th>api method</Table.Th>
                            <Table.Th>plan</Table.Th>
                            <Table.Th>group</Table.Th>
                            <Table.Th>actions</Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                        {methods.map((method, index) => {
                            console.log(method)
                            return (
                                <Table.Tr key={index}>
                                    <Table.Td>{method.id}</Table.Td>
                                    <Table.Td>{method.website_method}</Table.Td>
                                    <Table.Td>{method.api_method}</Table.Td>
                                    <Table.Td>{method.plan}</Table.Td>
                                    <Table.Td>{method.l_group}</Table.Td>
                                    <Table.Td>
                                        <Button size="xs" mr={5} variant="outline" color={"red"} onClick={() => {
                                            handleRemoveMethod(method.id);
                                        }}>Delete</Button>
                                    </Table.Td>
                                </Table.Tr>
                            )
                        }
                        )}
                    </Table.Tbody>
                </Table>
                    <Button onClick={handleAddMethod} variant="outline">Add method</Button>
            </Modal>
            <Modal size={"md"} opened={addMethodOpened} onClose={addMethodFunctions.close} title="Add Method">
                    <Flex justify="center" direction="column">
                        <TextInput ref={websiteMethod} required label="Website method"></TextInput>
                        <TextInput ref={apiMethod} required label="Api method"></TextInput>
                        <Select
                            label="Plan"
                            ref={plan}
                            required
                            placeholder="Choose"
                            data={[
                                {label: 'Basic', value: 'Basic'},
                                {label: 'VIP', value: 'VIP'}
                            ]}
                        />
                        <Select
                            label="Group"
                            ref={group}
                            required
                            placeholder="Choose"
                            data={[
                                {label: 'L4', value: 'L4'},
                                {label: 'L7', value: 'L7'}
                            ]}
                        />
                        <Center mt="md">
                            <Button onClick={handleSubmitAddMethod} variant="outline">Add method</Button>
                        </Center>
                    </Flex>
            </Modal>
            <Modal fullScreen opened={apiManagementOpened} onClose={apiManagement.close}
                     title="API Management" centered
                     overlayProps={{
                          backgroundOpacity: 0.55,
                          blur: 3,
                     }}
                     transitionProps={{transition: 'slide-up', duration: 600, exitDuration: 600}}>
                <Group>
                    API:
                    <Input
                        style={{width: '500px'}}
                        value={API}
                        onChange={(event) => setAPI(event.currentTarget.value)}
                    />
                    <Button onClick={() => {
                        setLoading(true)
                        fetch(getLinkPrefix()+'/addAPI', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({unique: localStorage.getItem("unique"), api: API})
                        }).then(response => response.json()).then(data => {
                            if(data.valid) {
                                fetch(getLinkPrefix()+'/getApiList', {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify({unique: localStorage.getItem('unique')})
                                }).then(response => response.json())
                                    .then(data => {
                                        if(data.valid) {
                                            setApiList(data.list);
                                        }
                                        notifications.show({
                                            title: 'API added',
                                            withCloseButton: false,
                                            color: 'green',
                                            autoClose: 1500
                                        })
                                        setLoading(false);
                                    });
                            } else {
                                notifications.show({
                                    title: 'Error',
                                    color: 'red',
                                    autoClose: 5000
                                })
                            }
                        })
                    }} variant="outline">Add</Button>
                </Group>
                <Table>
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th>#</Table.Th>
                            <Table.Th>api</Table.Th>
                            <Table.Th>actions</Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                        {apiList.map((api, index) => {
                            return (
                                <Table.Tr key={index}>
                                    <Table.Td>{api.id}</Table.Td>
                                    <Table.Td>{api.api}</Table.Td>
                                    <Table.Td>
                                        <Button size="xs" mr={5} variant="outline" color={"red"} onClick={() => {
                                            handleRemoveApi(api.id);
                                        }}>Delete</Button>
                                        <Button size="xs" color={"blue"} variant="outline" onClick={() => {
                                            handleEditMethods(api.id);
                                        }}>Edit methods</Button>
                                    </Table.Td>
                                </Table.Tr>
                            )
                        })}
                    </Table.Tbody>
                </Table>
            </Modal>
            <Modal size="xl" opened={attacksLogsOpened} onClose={attacksLogs.close}
                     title="Attacks logs" centered
                     overlayProps={{
                          backgroundOpacity: 0.55,
                          blur: 3,
                     }}
                     transitionProps={{transition: 'slide-up', duration: 600, exitDuration: 600}}>
                 <AttacksLogs />
            </Modal>
            <Modal size="xl" opened={loginLogsOpened} onClose={loginLogs.close}
                     title="Login logs" centered
                     overlayProps={{
                          backgroundOpacity: 0.55,
                          blur: 3,
                     }}
                     transitionProps={{transition: 'slide-up', duration: 600, exitDuration: 600}}>
                 <LoginLogs />
            </Modal>
            <Modal opened={opened} onClose={close}
                   title="Rules" centered
                   overlayProps={{
                       backgroundOpacity: 0.55,
                       blur: 3,
                   }}
                   transitionProps={{transition: 'slide-up', duration: 600, exitDuration: 600}}>
                <div style={{
                    textAlign: 'center'
                }}>
                    <p style={{margin: "5px 0px"}}>1. You can't share your key with anyone.</p>
                    <p style={{margin: "5px 0px"}}>2. You can't use this api to make your own database.</p>
                </div>
            </Modal>
            <Modal
                fullScreen
                opened={grantAccessOpened} onClose={grantAccess.close}
                   title="Access" centered
                   overlayProps={{
                       backgroundOpacity: 0.55,
                       blur: 3,
                   }}
                   transitionProps={{transition: 'slide-up', duration: 600, exitDuration: 600}}>
                <Group position="center">
                    <Input
                        placeholder="Search user"
                        onChange={(event) => {
                            const newUsers = usersCopy.filter((user) => user.username.toLowerCase().includes(event.currentTarget.value.toLowerCase()) || user.discord_id.toLowerCase().includes(event.currentTarget.value.toLowerCase()));
                            setUsers(newUsers);
                        }}
                    />
                    <DatePickerInput style={{width: '200px'}} placeholder="Pick date" value={date} onChange={setDate} />
                    <Button variant={"outline"} onClick={handleGrantAccess} color="green">Grant access</Button>
                    <Button variant={"outline"} onClick={handleRemoveAccess} color="red">Remove access</Button>
                </Group>
                <Table.ScrollContainer minWidth={500}>
                    <Table>
                        <Table.Thead>
                            <Table.Tr>
                                <Table.Th />
                                <Table.Th>username</Table.Th>
                                <Table.Th>discord_id</Table.Th>
                                <Table.Th>role</Table.Th>
                                <Table.Th>expires</Table.Th>
                            </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody>
                            {users.map((user) => (
                                <Table.Tr
                                    key={user.id}
                                    bg={selectedRows.includes(user.id) ? 'var(--mantine-color-bright-gold)' : undefined}
                                >
                                    <Table.Td>
                                        <Checkbox
                                            aria-label="Select row"
                                            checked={selectedRows.includes(user.id)}
                                            onChange={(event) =>
                                                setSelectedRows(
                                                    event.currentTarget.checked
                                                        ? [...selectedRows, user.id]
                                                        : selectedRows.filter((id) => id !== user.id)
                                                )
                                            }
                                        />
                                    </Table.Td>
                                    <Table.Td>{user.username}</Table.Td>
                                    <Table.Td>{user.discord_id}</Table.Td>
                                    <Table.Td>{user.role}</Table.Td>
                                    <Table.Td>{user.role == 'Admin' ? 'LIFETIME' : user.expires}</Table.Td>
                                </Table.Tr>
                            ))}
                        </Table.Tbody>
                    </Table>
                </Table.ScrollContainer>
            </Modal>
            <Modal opened={adminPanelOpened} onClose={adminPanel.close}
                   title="Admin Panel" centered
                   overlayProps={{
                       backgroundOpacity: 0.55,
                       blur: 3,
                   }}
                   transitionProps={{transition: 'slide-up', duration: 600, exitDuration: 600}}>
                <Flex gap="sm"
                      justify="center"
                      align="space-between"
                      direction="row"
                      wrap="wrap"
                >
                    <Button onClick={handleOpenGrantAccess} variant="outline">Access</Button>
                    <Button onClick={() => {
                        handleOpenLogs('login')
                    }} variant="outline">Login logs</Button>
                    <Button onClick={() => {
                        handleOpenLogs('stress')
                    }} variant="outline">Stress logs</Button>
                    <Button onClick={handleOpenApi} variant="outline">API Management</Button>
                </Flex>
            </Modal>
            <Modal opened={generateOpened} onClose={() => {
                generateFunctions.close();
                setGeneratedKey(null);
            }}
                   title="Generate new key" centered
                   overlayProps={{
                       backgroundOpacity: 0.55,
                       blur: 3,
                   }}
                   transitionProps={{transition: 'slide-up', duration: 600, exitDuration: 600}}>
                <Input.Wrapper mb={20} label="Expire" withAsterisk description=""
                               error="">
                    <DatePickerInput
                        placeholder="Pick date"
                        value={date}
                        onChange={setDate}
                    />
                </Input.Wrapper>
                <Checkbox
                    label="Admin?"
                    checked={admin}
                    onChange={(event) => setAdmin(event.currentTarget.checked)}
                />
                <Center>
                    <Button style={{width: '100px'}} onClick={async () => {

                    }} variant="outline">Grant access</Button>
                </Center>
                {generating && (
                    <h1>Generating...</h1>
                )}
                {generatedKey && (
                    <h1>Generated key: {generatedKey}</h1>
                )}
            </Modal>
            <Modal opened={dataOpened} onClose={dataFunctions.close}
                   title="Results" centered
                   overlayProps={{
                       backgroundOpacity: 0.55,
                       blur: 3,
                   }}
                   transitionProps={{transition: 'slide-up', duration: 600, exitDuration: 600}}>
                {data == null ? (
                    <h1>Loading...</h1>
                ) : (
                    <div>
                        <Table>
                            <Table.Thead>
                                <Table.Tr>
                                    <Table.Th>username</Table.Th>
                                    <Table.Th>ip</Table.Th>
                                    <Table.Th>database</Table.Th>
                                </Table.Tr>
                            </Table.Thead>
                            <Table.Tbody>
                                {data.map((row) => (
                                    <Table.Tr>
                                        <Table.Td>{row[2]}</Table.Td>
                                        <Table.Td style={{
                                            cursor: "pointer"
                                        }} onClick={() => {
                                            navigator.clipboard.writeText(row[1]);
                                            notifications.show({
                                                title: 'Copied to clipboard',
                                                withCloseButton: false,
                                                color: 'green',
                                                autoClose: 1500
                                            })
                                        }}>{row[1]}</Table.Td>
                                        <Table.Td>{row[0]}</Table.Td>
                                    </Table.Tr>
                                ))}
                            </Table.Tbody>
                        </Table>
                    </div>
                )}
            </Modal>
            <div className="bg-animation">
                <div id="stars"></div>
                <div id="stars2"></div>
                <div id="stars3"></div>
                <div id="stars4"></div>
            </div>
            <LoadingOverlay visible={loading} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
            {user == null ? (
                <Login />
            ) : (
                <>
                    <Title
                        className="title"
                        style={{
                            fontSize: '50px',
                        }} order={1}>
                        herbstresser.xyz
                    </Title>
                    {user && (user.role === "Admin" || user.role == "User") && (
                        <Stress />
                    )}
                    <Card
                        shadow="sm"
                        mt={20}
                        style={{width: 450, backgroundColor: "rgba(26,26,26,0.5)"}}
                        className={'price-list'}
                    >
                        {user == null ? (
                            <h1>Loading...</h1>
                        ) : (
                            <div style={{
                                textAlign: 'center'
                            }}>
                                <img style={{
                                    width: '50px',
                                    height: '50px',
                                    borderRadius: '50%'
                                }} src={"https://cdn.discordapp.com/avatars/"+user.id+"/"+user.avatar+".png"} alt={user.username}/>
                                <p style={{margin: "5px 0px"}}>Username: <span style={{
                                    color: "#FFD700",
                                    fontWeight: "bold"
                                }}>{user.username}</span></p>
                                <p style={{margin: "5px 0px"}}>Role: <span
                                    style={{color: "#FFD700", fontWeight: "bold"}}>{user.role}</span></p>
                                <p style={{margin: "5px 0px"}}>Expires: <span
                                    style={{color: "#FFD700", fontWeight: "bold"}}>{(user.role == 'Admin' ? 'LIFETIME' : user.expires) ?? 'Unknown'}</span></p>
                            </div>
                        )}
                    </Card>

                    <Group mt={10} mb={15} position="center">
                        {user && user.role === "Admin" && (
                            <Button onClick={handleAdminPanel} variant="outline" color="gray">Admin panel</Button>
                        )}
                        <Button onClick={open} variant="outline" color="gray">Rules</Button>
                        <Button onClick={() => {
                            notifications.show({
                                title: 'Logged out',
                                withCloseButton: false,
                                color: 'red',
                                autoClose: 1500
                            })
                            setUser(null);
                            localStorage.removeItem('token');
                            localStorage.removeItem('unique');
                        }} variant="outline" color="gray">Logout</Button>
                    </Group>
                    <a href="https://discord.gg/D9XNXdg37m" target="_blank">
                        <svg className={"discord"} style={{
                            marginTop: '20px',
                        }}
                             width="100" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 127.14 96.36">
                            <path className={"line"} fill="#fff"
                                  d="M107.7,8.07A105.15,105.15,0,0,0,81.47,0a72.06,72.06,0,0,0-3.36,6.83A97.68,97.68,0,0,0,49,6.83,72.37,72.37,0,0,0,45.64,0,105.89,105.89,0,0,0,19.39,8.09C2.79,32.65-1.71,56.6.54,80.21h0A105.73,105.73,0,0,0,32.71,96.36,77.7,77.7,0,0,0,39.6,85.25a68.42,68.42,0,0,1-10.85-5.18c.91-.66,1.8-1.34,2.66-2a75.57,75.57,0,0,0,64.32,0c.87.71,1.76,1.39,2.66,2a68.68,68.68,0,0,1-10.87,5.19,77,77,0,0,0,6.89,11.1A105.25,105.25,0,0,0,126.6,80.22h0C129.24,52.84,122.09,29.11,107.7,8.07ZM42.45,65.69C36.18,65.69,31,60,31,53s5-12.74,11.43-12.74S54,46,53.89,53,48.84,65.69,42.45,65.69Zm42.24,0C78.41,65.69,73.25,60,73.25,53s5-12.74,11.44-12.74S96.23,46,96.12,53,91.08,65.69,84.69,65.69Z"/>
                        </svg>
                    </a>
                </>
            )}
        </div>
    );
}

export default App;
