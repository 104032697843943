export const getLinkPrefix = () => {
    if(process.env.NODE_ENV === 'production') {
        return '';
    } else {
        return 'http://localhost:3002';
    }
}

export const validateKey = async (key) => {
    const response = await fetch(getLinkPrefix()+'/validate', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({key: key})
    });
    const data = await response.json();
    return data.valid;
}

export const getUser = async (key) => {
    const response = await fetch(getLinkPrefix()+'/getUser', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({key: key})
    });
    const data = await response.json();
    return data;
}

export const searchUser = async (key, username) => {
    const response = await fetch(getLinkPrefix()+'/searchUser', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({key: key, username: username})
    });
    const data = await response.json();
    return data;
}

export const generateNewKey = async (key, expire, admin) => {
    const response = await fetch(getLinkPrefix()+'/generateNewKey', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({key: key, expires: expire, admin: admin})
    });
    const data = await response.json();
    return data;
}

export const increaseLookups = async (key) => {
    const response = await fetch(getLinkPrefix()+'/increaseLookups', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({key: key})
    });
    const data = await response.json();
    return data;
}

export const addToLog = async (key, keyToBan) => {
    const response = await fetch(getLinkPrefix()+'/addToLog', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({key: key, keyToBan: keyToBan})
    });
    const data = await response.json();
    return data;
}

export const getUsers = async (key) => {
    const response = await fetch(getLinkPrefix()+'/getUsers', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({key: key})
    });
    const data = await response.json();
    return data;
}