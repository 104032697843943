import {Button, Card, Center, Title} from "@mantine/core";

const Login = () => {
    return (
        <>
            <Title
                className="title"
                style={{
                    fontSize: '50px',
                }} order={1}>
                herbstresser.xyz
            </Title>
            <Card
                shadow="sm"
                style={{width:350, backgroundColor: "rgba(26,26,26,0.5)"}}
                padding={40}
                mt={40}
            >
                <Center>
                    <Button size="md" component="a" href={process.env.NODE_ENV === "production" ? "https://discord.com/oauth2/authorize?client_id=1273485222100602881&response_type=code&redirect_uri=https%3A%2F%2Fherbstresser.xyz&scope=identify" : "https://discord.com/oauth2/authorize?client_id=1273485222100602881&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A3000&scope=identify"} style={{width: '200px'}} variant="outline">
                        Login via discord
                    </Button>
                </Center>
            </Card>
            <a href="https://discord.gg/D9XNXdg37m" target="_blank">
                <svg className={"discord"} style={{
                    marginTop: '20px',
                }}
                     width="100" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 127.14 96.36">
                    <path className={"line"} fill="#fff"
                          d="M107.7,8.07A105.15,105.15,0,0,0,81.47,0a72.06,72.06,0,0,0-3.36,6.83A97.68,97.68,0,0,0,49,6.83,72.37,72.37,0,0,0,45.64,0,105.89,105.89,0,0,0,19.39,8.09C2.79,32.65-1.71,56.6.54,80.21h0A105.73,105.73,0,0,0,32.71,96.36,77.7,77.7,0,0,0,39.6,85.25a68.42,68.42,0,0,1-10.85-5.18c.91-.66,1.8-1.34,2.66-2a75.57,75.57,0,0,0,64.32,0c.87.71,1.76,1.39,2.66,2a68.68,68.68,0,0,1-10.87,5.19,77,77,0,0,0,6.89,11.1A105.25,105.25,0,0,0,126.6,80.22h0C129.24,52.84,122.09,29.11,107.7,8.07ZM42.45,65.69C36.18,65.69,31,60,31,53s5-12.74,11.43-12.74S54,46,53.89,53,48.84,65.69,42.45,65.69Zm42.24,0C78.41,65.69,73.25,60,73.25,53s5-12.74,11.44-12.74S96.23,46,96.12,53,91.08,65.69,84.69,65.69Z"/>
                </svg>
            </a>
        </>
    )
}

export default Login;