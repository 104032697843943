import {Button, Card, Center, Group, LoadingOverlay, NumberInput, Select, TextInput} from "@mantine/core";
import {useEffect, useRef, useState} from "react";
import {getLinkPrefix} from "../ApiService";
import {notifications} from "@mantine/notifications";

const Stress = () => {
    const [isLoading, setLoading] = useState(false);
    const host = useRef(null);
    const port = useRef(null);
    const time = useRef(null);
    const method = useRef(null);
    const [methods, setMethods] = useState([]);

    useEffect(() => {
        setLoading(true)
        fetch(getLinkPrefix()+"/getMethodList", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({unique: localStorage.getItem("unique")})
        }).then(response => response.json()).then(data => {
            if(data.valid) {
                setMethods(data.methods)
                setLoading(false)
            } else {
                setLoading(false)
            }
        });
    }, []);

    const handleAttack = (e) => {
        e.preventDefault();
        setLoading(true)
        fetch(getLinkPrefix()+"/sendAttack", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({unique: localStorage.getItem("unique"), host: host.current.value, port: port.current.value, time: time.current.value, method: method.current.value})
        }).then(response => response.json()).then(data => {
            if(data.valid) {
                notifications.show({
                    title: 'Attack sent',
                    color: 'green',
                    autoClose: 1500,
                });
            } else {
                notifications.show({
                    title: 'Error',
                    message: data.error,
                    color: 'red',
                    autoClose: 1500,
                });

            }
            setLoading(false)
        });
    }
    return (
        <>
            <LoadingOverlay visible={isLoading} />
            <Card
                shadow="sm"
                style={{width: 450, backgroundColor: "rgba(26,26,26,0.5)"}}
                mt={40}
                padding={25}
            >
                <form onSubmit={handleAttack}>
                    <Group>
                        <TextInput ref={host} required label="HOST"></TextInput>
                        <NumberInput min={0} required ref={port} max={100000} label="PORT"></NumberInput>
                    </Group>
                    <NumberInput min={1} required ref={time} max={1800} label="TIME"></NumberInput>
                    {methods.length > 0 && <Select
                        label="METHOD"
                        ref={method}
                        required
                        placeholder="Choose"
                        data={methods}
                    />}
                    <Center mt="md">
                        <Button type="submit" variant="outline">ATTACK</Button>
                    </Center>
                </form>
            </Card>
        </>
    )
}

export default Stress